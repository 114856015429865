.negativeMargin {
    /* Hack time! The height of the nav header */
    margin-block-start: -96px;
}

.overflowClip {
    overflow-x: clip;
}

.region {
    padding-block-start: var(--fluid-32-72);
}

.cover {
    display: grid;
    grid-template-areas: 'cover';
}

.cover > * {
    grid-area: cover;
    place-self: center;
}

.cluster {
    display: flex;
    align-items: center;
}

.overview {
    padding-inline: var(--spacing-size-nine);
    padding-block: 0;
    width: 100%;
    justify-self: start;
    place-self: end;
    position: relative;
    text-wrap: balance;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto var(--spacing-size-nine);
}

.buyContainer {
    place-self: end;
    z-index: 2;
}

.title {
    place-self: start;
}

.mixBlend {
    z-index: 2;
    color: var(--color-brand-white);
    mix-blend-mode: difference;
}

.truefit {
    grid-column: 2;
    z-index: 3;
    justify-self: end;
    align-self: center;
    z-index: 3;
}

.fitContent {
    max-width: fit-content;
}

@media screen and (max-width: 991px) {
    .negativeMargin {
        margin-block-start: unset;
    }

    .overview {
        --gutter: var(--spacing-size-six);
        grid-template-columns: 1fr;
        padding-inline: var(--fluid-24-72);
        padding-block: 0 var(--fluid-32-72);
        grid-template-rows: auto auto;
    }

    .buyContainer {
        padding-top: var(--spacing-size-six);
        place-self: start;
    }

    .buyContainer > div {
        flex-direction: column;
        align-items: start;
    }

    .truefit {
        grid-column: 1;
        justify-self: start;
    }
}

@media screen and (max-width: 767px) {
    .overview {
        gap: unset;
    }

    .title[class*='mmds-title-two'] {
        font-size: var(--font-size-title5);
        line-height: var(--line-height-title5);
    }

    .price {
        padding-block-start: var(--spacing-size-five);
        padding-inline: var(--spacing-size-five);
    }
}
