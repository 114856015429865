.header {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.drawerBody {
    padding-top: 0;
}

.divider {
    border-bottom: 1px solid var(--color-brand-black);
    margin-bottom: var(--spacing-size-five) 0;
}

.fieldSet {
    border: none;
    padding: 0;
}

.stack > * + * {
    margin-top: var(--spacing-size-five);
}

.text {
    margin-block: var(--spacing-size-eight)
        calc(var(--spacing-size-seven) + 2px);
}

.field {
    display: flex;
    flex-direction: column;
}

.signupField {
    color: var(--text-secondary);
}

.footer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
