.root {
    display: flex;
    align-items: center;
    gap: var(--spacing-size-three);
    padding-block: var(--spacing-size-two);
}

.truefitCTASkeleton {
    display: flex;
    align-items: center;
    width: 240px;
    height: 56px;
}

@media screen and (max-width: 991px) {
    .root {
        grid-column: 1;
        justify-self: start;
    }

    .truefitCTASkeleton {
        grid-column: 1;
        justify-self: start;
    }
}

@media screen and (max-width: 767px) {
    .root {
        padding-inline: var(--spacing-size-five);
        padding-block-end: var(--spacing-size-five);
        text-wrap: nowrap;
    }

    .root > svg {
        min-width: 16px;
    }

    .root > span + button {
        margin-inline-start: auto;
    }

    .truefitCTASkeleton {
        padding-inline-start: var(--spacing-size-five);
        height: 72px;
    }

    .fitContent {
        max-width: fit-content;
    }
}
